import { render, staticRenderFns } from "./GlobalCss.vue?vue&type=template&id=72460dd8&scoped=true&"
import script from "./GlobalCss.vue?vue&type=script&lang=js&"
export * from "./GlobalCss.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72460dd8",
  null
  
)

export default component.exports