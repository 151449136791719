<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Global CSS"
                >
                  <template v-slot:description>
                    Main font: "Graphik Web", Download here:<hb-link class="ml-1" color="secondary" href="https://drive.google.com/drive/folders/134VqCqMKsl91hwxC8X_yUG9-ju5Md6-D?usp=sharing" target="_blank">https://drive.google.com/drive/folders/134VqCqMKsl91hwxC8X_yUG9-ju5Md6-D?usp=sharing</hb-link>
                  </template>
                </hb-page-header>
            </hb-header>
        </div>

       <hb-card title="Required CSS Styles" class="mt-4 mb-6">
          <div class="mt-4 mb-6 mx-6">
              These are required css classes and styles for hummingbird aviary components to work and display properly, you must install 'Graphik Web' and place these definitions in your global css style sheet and use them everywhere:
          </div>

          <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
html {
  font-size: 14px;
}

#app {
  font-size: 14px;
  font-family: 'Graphik Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #101318;
}

html .v-application .primary {
  color: #00848E !important;
  background-color: #00848E !important;
  border-color: #00848E !important;
}

html .v-application .primary--text {
  color: #00848E !important;
  caret-color: #00848E !important;
}

.v-ripple__container {
  opacity: 0 !important;
}

a:focus, button:focus {
  outline: none;
  border: none;
}

.hb-overflow-handler {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hb-font-header-1 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
}

.hb-font-header-2-medium {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
}

.hb-font-header-2 {
    font-size: 24px;
    line-height: 32px;
}

.hb-font-body-medium {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
}

.hb-font-body {
    font-size: 14px;
    line-height: 24px;
}

.hb-font-caption-medium {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.hb-font-caption {
    font-size: 12px;
    line-height: 16px;
}

.hb-primary-lighter {
  background-color: #E0F5F5;
}

.hb-primary-light{
  background-color: #8DE3E3;
}

.hb-primary {
  background-color: #47C0BF;
}

.hb-primary-dark {
  background-color: #00848E;
}

.hb-primary-darkest {
  background-color: #003135;
}

.hb-cloud-lighter {
  background-color: #F9FAFB;
}

.hb-cloud-light {
  background-color: #F4F6F8;
}

.hb-cloud {
  background-color: #DFE3E8;
}

.hb-cloud-dark {
  background-color: #C4CDD5;
}

.hb-text-light {
  color: #637381;
}

.hb-text-night {
  color: #101318;
}

.hb-text-error {
  color: #FB4C4C;
}

.v-application a.hb-link, .hb-link {
  color: #00848E;
  text-decoration:none;
}

.v-application a.hb-link-secondary, .hb-link-secondary {
  color: #306FB6;
  text-decoration:none;
}

.hb-success {
  background-color: #02AD0F;
}

.hb-success-shade {
  background-color: #E1FAE3;
}

.hb-caution {
  background-color: #FFD600;
}

.hb-caution-shade {
  background-color: #FFF9DA;
}

.hb-error {
  background-color: #FB4C4C;
}

.hb-error-shade {
  background-color: #F8E5E1;
}

.hb-guidance{
  background-color: #3D8FFF;
}

.hb-guidance-shade{
  background-color: #E7F1FF;
}

.hb-chart-pink {
  background-color: #CE6EE2;
}

.hb-chart-green {
  background-color: #4AC3AB;
}

.hb-chart-orange {
  background-color: #FAA838;
}

.hb-chart-royal-blue {
  background-color: #4B5FC7;
}

.hb-chart-cyan {
  background-color: #67CFF5;
}

.hb-chart-gray {
  background-color: #C6CDD4;
}

.hb-chart-pumpkin {
  background-color: #F17A37;
}

.hb-chart-red {
  background-color: #E24F60;
}

.hb-chart-teal {
  background-color: #38AFC6;
}

.hb-chart-yellow {
  background-color: #E4DE3E;
}

.hb-inline-sentence-text {
  padding: 5px 8px 24px 0;
}

.hb-elevation-small {
  box-shadow: 0px 2px 4px 2px rgba(71, 84, 116, 0.1) !important;
}

.hb-elevation-medium {
  box-shadow: 0px 4px 10px 3px rgba(71, 84, 116, 0.1) !important;
}

.hb-elevation-large {
  box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2) !important;
}

.hb-elevation-blade {
  box-shadow: 8px 0px 18px rgba(71, 84, 116, 0.2) !important;
}

.hb-elevation-bulk-edit-blade {
  box-shadow: -8px 0px 18px rgba(71, 84, 116, 0.2) !important;
}

.hb-elevation-table-panel-left {
  box-shadow: 4px 0px 10px rgba(71, 84, 116, 0.1) !important;
}

.hb-elevation-table-panel-right {
  box-shadow: -4px 0px 10px rgba(71, 84, 116, 0.1) !important;
}

.hb-border-default {
  border: 1px solid #DFE3E8 !important; 
}
</pre>
          </hb-card>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemGlobalCss",
        data: function() {
            return {
            };
        },
    }
</script>

<style scoped>

</style>
